function deleteOrder(e){
    var url = new URL(`https://admin.comfeitico.com/api/pedidos/${e.id}/delete`);
    var requestOptions = {
        method: 'POST',
    };
    fetch(url, requestOptions).then(res => res.json())
    .then(
        (result) => {
            return result;
        },
        (error) => {
            console.log(error.toString())
        }
    )
    
}

export default deleteOrder;