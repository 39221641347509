function addItemToOrder(PedidoID,ItemID,qtd,obs){
    var url = new URL(`https://admin.comfeitico.com/api/pedidos/${PedidoID}/add/${ItemID}`);
    url.searchParams.append("qtd",qtd);
    url.searchParams.append("obs",obs);
    var requestOptions = {
        method: 'POST',
    };
    return fetch(url, requestOptions).then(res => res.json())
            
            
}

export default addItemToOrder;