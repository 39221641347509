import { useState } from "react";
import SeletorItem from "../itemSeletor";

function TelaAdcionarItemPedido({adcionarAoPedido}){
    const [mode,setMode] = useState(0);
    const [item,setItem] = useState({})
 
    const [qtd,setQtd] = useState(1.0);
    const [obs,setObs] = useState("")

    function handleSelectItem(item){
        console.log(item);
        setItem(item);
        setMode(1);
    }

    if (mode === 0) {
        return <div>
                <button className="fixedBackButton" onClick={e => setMode(0)}>Voltar</button>
                Adicionar item 
                <SeletorItem select={handleSelectItem}></SeletorItem>
        </div>
    } else {
        return <div className="App-header">
                <button className="fixedBackButton" onClick={e => setMode(0)}>Voltar</button><br/>
                
                <b>Nome:</b>{item.nome}<br/>
                <b>Preço:</b>{item.preco}<br/>
                <b>Quantidade:</b>
                <input
				name="qtdbox"
				value={qtd}
				onChange={e => setQtd(e.target.value)}
				>
                </input>
                <input
				name="obsbox"
				value={obs}
				onChange={e => setObs(e.target.value)}
				>
                </input>
                <button onClick={e => adcionarAoPedido(item,qtd,obs)}>Adcionar</button>
        </div>
    }
}

export default TelaAdcionarItemPedido;