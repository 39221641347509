import React, { useState, useEffect } from 'react';
import './App.css';
import ItemContainer from './components/ItemContainer';

function SeletorItem(props) {
    const [fresh,setFresh] = useState(false);
    const [pesquisa,setPesquisa] = useState("");
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    function refreshPesquisa(i){
        setPesquisa(i);
        setFresh(false);
    }

    function SubmitPesquisa(i){
        
    }
    
    useEffect(() => {
        if(fresh === false){
            var url = new URL(`https://admin.comfeitico.com/api/produtos`);
            if (pesquisa !== "") {
                url.searchParams.append("pesquisa",pesquisa);
            }
            fetch(url)
            .then(res => res.json())
        .then(
            (result) => {
            console.log(result)
            setIsLoaded(true);
            setItems(result.produtos);
            setError(false);
            setFresh(true);
            },
            // Nota: é importante lidar com errros aqui
            // em vez de um bloco catch() para não receber
            // exceções de erros reais nos componentes.
            (error) => {
            setIsLoaded(true);
            setError(error);
            }
        )
    }
    },[fresh])

    if (error) {
        return <div>
            Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>
                <input
                name="searchbox"
                className="searchbox"
                value={pesquisa}
                onSubmit={e => refreshPesquisa(e.target.value)}
                >
                </input>
                Loading...
            </div>;
        } else {
            return <div>
                <div>
                    <Searchbox pesquisa={pesquisa} refreshPesquisa={refreshPesquisa} SubmitPesquisa={SubmitPesquisa}> </Searchbox>
                </div> <br/>
                <ItemList items={items} select={props.select}/>
            </div>
        }
}

function Searchbox(props) {
    return <div>
        <input
            name="searchbox"
            className="searchbox"
            onChange={e => props.refreshPesquisa(e.target.value)}
        >
        </input>
    </div>
}

function ItemList(props) {
    return <div className="wrapper">
    {props.items.map(item => (
    <ItemContainer item={item} select={props.select}/>
    ))}
    </div>
}

// function ItemContainer(props) {
//     return <button onClick={e => props.select(props.item)} className="EntregaCard"> 
//         <h2>{props.item.nome}</h2> <br/>
//         <b>Codigo:</b>{props.item.id} <br/>
//         <b>Desc:</b> {props.item.descricao} <br/>
//         <b>Preço:</b>{props.item.preco} <br/>
//     </button>
// }

export default SeletorItem