import React, { useState, useEffect } from 'react';
import './App.css';
import FormNovoPedido from './novoPedido';
import SeletorItem from './itemSeletor';
import ReactToPrint from 'react-to-print';

import TelaPedido from './components/telaPedido';

import MainButton from './components/mainbutton.js';
import Card from './components/card';
import Grid from './components/grid';

function PedidosList() {
    const [fresh,setFresh] = useState(false);
    const [mode,setMode] = useState(0);
    const [pesquisa,setPesquisa] = useState("");
    const [pedidoSelecionado,setPedido] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [itemSelecionado,selectItem] = useState(0);
    const [qtd,setQtd] = useState(1.0);
    const [obs,setObs] = useState("");
    

    useEffect(() => {
        if(fresh === false){
            var url = new URL("https://admin.comfeitico.com/api/pedidos/");
            if(pesquisa !== ""){
                url.searchParams.append("pesquisa",pesquisa);
            } else {
                url.searchParams.append("recente",true);
            }
            setIsLoaded(false);
            fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                console.log(result)
                setIsLoaded(true);
                setItems(result.result);
                setError(false);
                setFresh(true);
                },
                (error) => {
                setIsLoaded(true);
                setError(error);
                }
            )
        }
    },[fresh])

    function editPedido(data,id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        fetch(`https://admin.comfeitico.com/api/pedidos/${id}/edit/direct`, requestOptions)
            .then(response => response.json())
            .then(data => this.setState({ postId: data.id }));
    }

    function returnRefresh(){
        setMode(0);
        setFresh(false);
    }

    function selectPedido(i){
        setPedido(i);
        setMode(2);
    }

    function refreshPesquisa(i){
        setPesquisa(i);
        setFresh(false);
    }



    function chooseItem(e) {
        selectItem(e)
        setMode(4)
    }

    
    

    

    if(mode === 0) {
        return <div className="App-header">
            <MainButton text={"+ Pedido"} action={e => setMode(1)}/>
            <input
            name="searchbox"
            className="searchbox"
            onChange={e => refreshPesquisa(e.target.value)}
            >
            </input>
            {/* <button className="fixedButton" onClick={e => setMode(1)}><b> + Pedido </b></button> */}
            <PedidosRecentes pesquisa={pesquisa} modo={setMode} selectPedido={selectPedido} error={error} isLoaded={isLoaded} items={items}></PedidosRecentes>
        </div>
    } else if (mode === 1) {
        return <div className="App-header">
            <FormNovoPedido return={returnRefresh}/>
            <button className="fixedBackButton" onClick={e => setMode(0)}>Voltar</button>
            
        </div>
    } else if (mode === 2){
        return <div className="App-header">
                <button className="fixedBackButton" onClick={e => setMode(0)}>Voltar</button>
                <TelaPedido ped={pedidoSelecionado} setMode={setMode}/>
        </div>
    }  else {
        return <div className="App-header">
            Erro. Modo não encontrado.
            <button className="fixedBackButton" onClick={e => setMode(0)}>Voltar</button>
        </div>
    }

}



function PedidosRecentes(props) {

  if (props.error) {
    return <div>
        Error: {props.error.message}</div>;
    } else if (!props.isLoaded) {
        return <div>Loading...</div>;
    } else {
        return <div>
            {console.log(props.items)}
            <VisualizadorPedidos items={props.items} selectPedido={props.selectPedido}/>
        </div>
    }
}

function VisualizadorPedidos(props) {
    
    return <div>
        <h3>Pedidos Recentes:</h3>
        <Grid>
            {props.items.map(item => (
                <EntregaCard key={item.id} item={item} selectPedido={props.selectPedido} />
      ))}
        </Grid>
        </div>
    
}

function EntregaCard(props) {
    return <Card onClick={e => props.selectPedido(props.item)}>
                <b>
                    {ConvertDataBonita(props.item.entrega)}
                </b> 
                <br/> 
                {props.item.cliente.nome} 
        </Card>  

    
}

function CommaFormat(s){
    if (s != "")
    {
        return `, ${s}`
    } else {
        return ``
    }
}


function PrecoTotal(props){
    var PrecoT = 0;
    for (var i = 0; i < props.item.items.length; i++) { 
        PrecoT += props.item.items[i].preco * props.item.items[i].qtd; 
    }
    return <div>{FormatPrice(PrecoT)}</div>
}



function FormatPrice(s) {
    return new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(s);
}




function ConvertDataBonita(data) {
    var ar = data.split("-")
    var newdate = `${ar[3]}:${ar[4]} ${ar[2]}/${ar[1]}/${ar[0]}`
    return newdate
}



export default PedidosList;