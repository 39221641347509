function removeOrderItem(id,pid,obs,qtd){
    var url = new URL(`https://admin.comfeitico.com/api/pedidos/${id}/remove/${pid}`);
    url.searchParams.append("qtd",qtd);
    url.searchParams.append("obs",obs);
    var requestOptions = {
        method: 'POST',
    };
    fetch(url, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    return result;
                },
                (error) => {
                    console.log(error.toString())
                }
            )
}

export default removeOrderItem;