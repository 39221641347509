import styled, { css } from 'styled-components'

const Select = styled.select`
	border-radius: 36px;
	font-size: 1em;
	font-weight: 300;
`

function EntregaSelect({ value , setValue }) {
return <div>
  <Select onChange={e => setValue(e.target.value)} name="entregas" id="pag">
	  	<option value={705}>Manaira / Sem taxa de entrega</option>
		<option value={701}>Bessa / Miramar / Jardim Luna / Tambau / Cabo Branco / Jardim Oceania</option>
		<option value={702}>Intermares / Altiplano / Expedicionarios / Tambauzinho</option>
		<option value={703}>Ponta de Campina / Poço / Centro / Bairro dos Estados</option>
		<option value={704}>Portal do sol /Seixas /Camboinha</option>
	</Select>
</div>
}

export default EntregaSelect;