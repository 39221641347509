import styled, { css } from 'styled-components'

const Button = styled.button`
  position: fixed;
  bottom: 5%;
  right: 10%; 
  background-color:#f8f8f8;
  color:rgb(255, 0, 200);
  border-radius: 36px;
  font-size: 2em;
  font-weight: bold;
  z-index: 1;
`

function MainButton({ action , text }) {
return <div>
    <Button onClick={action}>{text}</Button>
</div>

}

export default MainButton;