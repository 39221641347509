import React, { useState, useEffect } from 'react';
import './App.css';

function FormNovoItem() {
    const [id, setId] = useState(0);
    const [nome, setNome] = useState("Nome");
    const [preco, setPreco] = useState(10.00);
    const [desc, setDesc] = useState("");
    const [peso, setPeso] = useState(true);
    
    const [submit,setSubmit] = useState(false)
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    
    useEffect(() => {
        if (submit === false) {
            return;
        }
        var requestOptions = {
            method: 'POST',
        };
        var url = new URL("https://admin.comfeitico.com/api/produto");
        url.searchParams.append("nome",nome);
        url.searchParams.append("preco",preco);
        url.searchParams.append("peso",peso);
        url.searchParams.append("desc",desc);
        url.searchParams.append("id",id);
        setIsLoaded(false);
        fetch(url, requestOptions)
        .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          setIsLoaded(true);
          setItems(result.result);
          setError(false);
        },
        // Nota: é importante lidar com errros aqui
        // em vez de um bloco catch() para não receber
        // exceções de erros reais nos componentes.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )


  },[submit])

    
    if (submit === false){
    return <div>
        <b>Novo Item</b>
        <form>
            <b>ID:</b><br/>
            <input
			name="id"
            type="number"
            value={id}
            onChange={e => setId(e.target.value)}
			></input><br/>
            <b>Nome:</b><br/>
            <input
			name="nome"
            type="text"
            value={nome}
            onChange={e => setNome(e.target.value)}
			></input><br/>
            <b>Preço:</b><br/>
            <input
			name="preco"
            type="float"
            value={preco}
            onChange={e => setPreco(e.target.value)}
			></input><br/>
            <b>Descrição:</b><br/>
            <textarea
            name="desc"
            defaultValue="Descrição do produto"
            value={desc}
            onChange={e => setDesc(e.target.value)}
            >
            </textarea><br/>
            Por peso:
            <input 
            type="checkbox"
            value={peso}
            onChange={e => setPeso(e.target.value)}
            >
            </input><br/>
            <input
            type="submit"
            onClick={e => setSubmit(true)}
            >
            </input>
        </form>
    </div>
    } else {
        return <b> Test</b>
    }

}

export default FormNovoItem;