import styled from "styled-components"

const Card = styled.div`
    background-color: white;
    border-radius: 10px;
    text-align: center;
    padding: 20px;   
    font-weight: 600;
    font-size: 1.2rem;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    color: black;
`
export default Card;