import styled, { css } from 'styled-components'
import InformationField from './informationfield';
import React, { useState, useEffect } from 'react';

const DeleteButton = styled.button`
	border-radius: 36px;
	font-size: 1em;
	font-weight: 300;
`

function TelaEndereco(props){
	const [edit,setEdit] = useState(false);
	const [cidade, setCidade] = useState(props.endereco.cidade);
    const [bairro, setBairro] = useState(props.endereco.bairro);
    const [rua, setRua] = useState(props.endereco.rua);
    const [nr, setNr] = useState(props.endereco.nr);
    const [complemento, setComplemento] = useState(props.endereco.complemento);
    const [ref, setRef] = useState(props.endereco.referencia);
    const [cep, setCEP] = useState(props.endereco.cep);
    const [nome, setNome] = useState(props.endereco.nome);

	function EditButtons(){
		if (edit === false) {
			return <div>
				<button onClick={e => setEdit(!edit)}>
					Editar
				</button>
			</div>
		} else {
			return <div>
				<button onClick={e => setEdit(!edit)}>
					Cancelar
				</button>
				<button onClick={e => SaveEdit()}>
					Salvar
				</button>
			</div>
		}
	}


	function SaveEdit(){
		// EditClient(props.item.id,nome,telefone,email,obs,props.refresh);
		EditEndereco(props.cliente.id,props.endereco.id,nome,cidade,bairro,rua,nr,complemento,ref,cep,props.refresh)
		setEdit(false);
	}

	return <div>
		<button onClick={e => props.backButton()}>Voltar</button>
		<InformationField label="" value={nome} setValue={setNome} edit={edit}/>
		<InformationField label="Cidade" value={cidade} setValue={setCidade} edit={edit}/>
		<InformationField label="Bairro" value={bairro} setValue={setBairro} edit={edit}/>
		<InformationField label="Rua" value={rua} setValue={setRua} edit={edit}/>
		<InformationField label="Nr" value={nr} setValue={setNr} edit={edit}/>
		<InformationField label="Complemento" value={complemento} setValue={setComplemento} edit={edit}/>
		<InformationField label="Referencia" value={ref} setValue={setRef} edit={edit}/>
		<InformationField label="CEP" value={cep} setValue={setCEP} edit={edit}/>
		<EditButtons/>
        <DeleteButton onClick={() => DeleteEndereco(props.cliente.id,props.endereco.id,props.refresh)}>
            Deletar
        </DeleteButton>
	</div>
}
 
function DeleteEndereco(id,eid,refresh) {
    var requestOptions = {
		method: 'DELETE',
	};
    var url = new URL(`https://admin.comfeitico.com/api/cliente/${id}/endereco/`);
    url.searchParams.append("eid",eid)
    fetch(url,requestOptions)
    .then(res => res.json())
    .then((result) => {
        refresh(false)
    },(error) => {
        refresh(false)
        console.log(error)
    }	
	)
}

function EditEndereco(id,eid,nome,cidade,bairro,rua,nr,complemento,referencia,cep,refresh) {
	var requestOptions = {
		method: 'POST',
	};
	var url = new URL(`https://admin.comfeitico.com/api/cliente/${id}/edit/endereco/${eid}`);
	url.searchParams.append("nome",nome);
	url.searchParams.append("cidade",cidade);
	url.searchParams.append("bairro",bairro);
	url.searchParams.append("rua",rua);
	url.searchParams.append("nr",nr);
	url.searchParams.append("comple",complemento);
	url.searchParams.append("ref",referencia);
	url.searchParams.append("cep",cep);
	fetch(url, requestOptions)
    .then(res => res.json()).then(
		refresh(false)
	)
}

export default TelaEndereco;