import React, { useState, useEffect } from 'react';
import './App.css';

function SeletorEndereco(props) {
    return <div>
        <h2>Endereços</h2>
			{props.item.enderecos.map(item => (
				<EnderecoInfo item={item} enderecoSelect={props.enderecoSelect}></EnderecoInfo>
			))}
    </div>
}

function EnderecoInfo(props) {
    return <div>
		<button className="EntregaCard" onClick={e => props.enderecoSelect(props.item)}>
			<h2>{props.item.nome}</h2>
			{props.item.rua} {props.item.nr} {props.item.bairro}, {props.item.cidade}
		</button>
	</div>
}

export default SeletorEndereco;