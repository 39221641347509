import { useState } from "react";


import removeOrderItem from "../services/removeOrderItem";
import deleteOrder from "../services/deleteOrder";
import addItemToOrder from "../services/addItemToOrder";

import TelaAdcionarItemPedido from "./adcionarItemTela";
import getPedido from "../services/getPedido";

function TelaPedido({ped}) {
    const [edit,setEdit] = useState(false);
    const [mode,setMode] = useState(0)
    const [pedido,setPedido] = useState(ped)
    const [itemSelecionado,selectItem] = useState(0);
    const [qtd,setQtd] = useState(1.0);
    const [obs,setObs] = useState("");


    function deletarPedido(pedido) {
        deleteOrder(pedido)
    }

    function adcionarAoPedido(item,qtd,obs){
        addItemToOrder(pedido.id,item.id,qtd,obs)
        .then(
            (result) => {
                console.log(result);
                setPedido(result)
                setMode(0);
           },
            (error) => {
                console.log(error)
            }
        )
        
        

        
    }

    if (mode === 0) {
        return <div>
            <button>Editar</button> 
            
            <div id="pedidoInfo">
                <div>
                    <BasicPedidoInfo item={pedido} edit={edit}></BasicPedidoInfo> <br/>
                    <EnderecoInfo item={pedido} edit={edit}></EnderecoInfo> <br/>
                    <Obst item={pedido} edit={edit}></Obst>
                </div>
                <ItemsInfo item={pedido} removeItem={removeOrderItem}></ItemsInfo> <br/>
                <PagamentoInformation pedidoSelecionado={pedido} edit={edit}/>
                <button onClick={e => setMode(1)}> + Item </button> <br/>
                <button className="deleteButton" onClick={e => deletarPedido(pedido)}> Cancelar Pedido </button>
            </div>
        </div>
    }else if (mode === 1){
        return <TelaAdcionarItemPedido adcionarAoPedido={adcionarAoPedido} />  
    } else {
        <div>
            Erro de modo.
        </div>
    }
}


function PagamentoInformation(props) {
    const [pag,setPag] = useState(props.pedidoSelecionado.pag);

    if (!props.edit) {
        return <div>
            Pagamento: {props.pedidoSelecionado.pag}<br/>
        </div>
    } else {
        return <div>
            Pagamento: <select onChange={e => setPag(e.target.value)} name="pagamentos" id="pag">
            <option value="PIX">Pix</option>
            <option value="Cartão">Cartão</option>
            <option value="Transferencia">Transferencia</option>
            <option value="Outro">Outro</option>
            </select>
        </div>
    }
}

function CommaFormat(s){
    if (s != "")
    {
        return `, ${s}`
    } else {
        return ``
    }
}

function EnderecoInfo(props) {
    return <div>
        <b>Endereço: {props.item.endereco.nome} </b><br/>
        <b>Rua:</b> {`${props.item.endereco.rua} ${props.item.endereco.nr}${CommaFormat(props.item.endereco.complemento)}, ${props.item.endereco.bairro}, ${props.item.endereco.cidade}`} <br/>
        <b>Referencia:</b> {props.item.endereco.refencia} <br/>
    </div>
}

function Obst(props){
    if (props.item.obs !== "") {
        return <div>Observação:{props.item.obs} <br/></div>
    } else {
        return <div></div>
    }
}

function PrecoTotal(props){
    var PrecoT = 0;
    for (var i = 0; i < props.item.items.length; i++) { 
        PrecoT += props.item.items[i].preco * props.item.items[i].qtd; 
    }
    return <div>{FormatPrice(PrecoT)}</div>
}

function ItemsInfo(props) {
    return <div>
        <table className="sheet">
            <tr>
                <th>Qtd</th>
                <th>Nome</th>
                <th>Observação</th>
                <th>Preço Un.</th>
                <th>Preço</th>
                <th></th>
            </tr>
            {props.item.items.map((item, index) => (
                <tr>
                    <th>{item.qtd}</th>
                    <th>{item.nome}</th>
                    <th>{item.obs}</th>
                    <th>{item.preco}</th>
                    <th>{FormatPrice(item.preco * item.qtd)}</th>
                    <th><button onClick={e => props.removeItem(item.id,item.obs,item.qtd)}> Remover </button></th>
                </tr>
            ))}
            <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th><PrecoTotal item={props.item}/></th>
            </tr>
        </table>
    </div>
}

function FormatPrice(s) {
    return new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(s);
}

function FormatTelefone(s){
    var ddd = s.toString().slice(0,2);
    var nr1 = s.toString().slice(2,8);
    var nr2 = s.toString().slice(8);
    return `(${ddd}) ${nr1} - ${nr2} `
}

function BasicPedidoInfo(props) {
    return <div className="PedidoInfo">
        {ConvertDataBonita(props.item.entrega)}<br/>
        {props.item.cliente.nome} - 
        {/* <b>Data de Entrega:</b> {ConvertDataBonita(props.item.entrega)} <br/> */}
        {FormatTelefone(props.item.cliente.telefone)}<br/>
        
    </div>
}

function ConvertDataBonita(data) {
    var ar = data.split("-")
    var newdate = `${ar[3]}:${ar[4]} ${ar[2]}/${ar[1]}/${ar[0]}`
    return newdate
}

function Telefones(props) {
    return <div>
        {props.items.map((item, index) => (
                <div>
                    <li key={index}>
                        {item}
                    </li>
                    
                </div>
      ))}
    </div>
}

export default TelaPedido;