import React, { useState, useEffect } from 'react';
import './App.css';
import logo from './logo.png';
function ModeSwitch(props){

    return <div className="wrapper">
        <h1 className="pageTitle">{props.mode}</h1>
        <div>
            <ModeSwitchButton switch={props.switch} destiny="Pedidos" mode={props.mode}></ModeSwitchButton>
            <ModeSwitchButton switch={props.switch} destiny="Items" mode={props.mode}></ModeSwitchButton>
            <ModeSwitchButton switch={props.switch} destiny="Clientes" mode={props.mode}></ModeSwitchButton>
        </div>
        
        
    </div>
}

function ModeSwitchButton(props) {
    if (props.mode === props.destiny) {
        return <button 
        onClick={() => { props.switch(props.destiny) }}
        className="selectedButton topbarButton">
            {props.destiny}
        </button>
    } else {
        return <button onClick={() => { props.switch(props.destiny) }} className="topbarButton">
            {props.destiny}
        </button>
    }
    
}

export default ModeSwitch;