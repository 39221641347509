import InformationField from "./informationfield";
import React, { useState, useEffect } from 'react';
import FormNovoEndereco from './novoEndereco';
import GetClient from "../services/getClient";
import TelaEndereco from "./telaEndereco";

function ClienteInformacoes({cli}) {
	const [mode,setMode] = useState(0)
	const [edit,setEdit] = useState(false);
	const [cliente,setCliente] = useState(cli)
	const [telefone, setTelefone] = useState(cliente.telefone);
    const [nome, setNome] = useState(cliente.nome);
    const [email,setEmail] = useState(cliente.email);
    const [obs,setObs] = useState(cliente.obs);
    const [enderecoSelecionado,setEnderecoselecionado] = useState(0)


    const [loading,setLoading] = useState(false)
	const [error,setError] = useState("")

	function refreshJustClient() {
		setLoading(true);
        var requestOptions = {
            method: 'GET',
        };
        var url = new URL(`https://admin.comfeitico.com/api/cliente/${cliente.id}`);
		console.log("prefetch")
        fetch(url,requestOptions)
        .then(res => res.json()).then(
            (result) => {
                setCliente(result);
				setLoading(false);
				setError("");
            },
            (error) => {
				setError(error.toString());
            }
        )
	}


    function enderecoSelect(eid){
		setMode(1);
		setEnderecoselecionado(eid);
	}

    function backButton(){
        setMode(0);
    }
        
	function EditButtons(){
		if (edit === false) {
			return <div>
				<button onClick={e => setEdit(!edit)}>
					Editar
				</button>
			</div>
		} else {
			return <div>
				<button onClick={e => setEdit(!edit)}>
					Cancelar
				</button>
				<button onClick={e => SaveEdit()}>
					Salvar
				</button>
			</div>
		}
	}

	function SaveEdit(){
		EditClient(cli.id,nome,telefone,email,obs,refreshJustClient);
		setEdit(false);
	}

    if(loading) {
		return <div> Carregando... { (Error != "") && <div>{`Error:${error}`}</div> }  </div>
	} 

    if(mode === 0){
		return <div>
			<div className="wrapper">
				<div>
					<InformationField label="" value={nome} setValue={setNome} edit={edit}/>
					<InformationField label="Telefone" value={telefone} setValue={setTelefone} edit={edit}/>
					<InformationField label="Email" value={email} setValue={setEmail} edit={edit}/>
					<InformationField label="Observação" value={obs} setValue={setObs} edit={edit}/>
					<EditButtons/>
				</div>
				<div>
					<h2>Endereços</h2>
					{cliente.enderecos.map(item => (
						<EnderecoInfo item={item} enderecoSelect={enderecoSelect} key={item.id}></EnderecoInfo>
					))}
					<BotaoNovoEndereço cliente={cliente} refresh={refreshJustClient}/>
				</div>
				<div>
					<h2>Pedidos</h2>
					{cliente.pedidos.map(item => (
						<PedidoVisualizer key={item.id} item={item}/>
					))}
				</div>
			</div>
		</div>
    } else {
        return <TelaEndereco endereco={cliente.enderecos[enderecoSelecionado]} cliente={cliente} backButton={backButton} refresh={refreshJustClient}/>
    }
}

function PedidoVisualizer(props) {
	return <div className="card">
		{props.item.id} <br/>
		Entrega: {ConvertDataBonita(props.item.entrega)}
	</div>
}

function ConvertDataBonita(data) {
    var ar = data.split("-")
    var newdate = `${ar[3]}:${ar[4]} ${ar[2]}/${ar[1]}/${ar[0]}`
    return newdate
}

function BotaoNovoEndereço({cliente,refresh}) {
	const [mode,setMode] = useState(false);

	function handleRefresh(){
		refresh()
		setMode(false);
	}

	if (mode) {
		return <div>
			<button onClick={e => setMode(!mode)}>
				Cancelar
			</button>
			<FormNovoEndereco id={cliente.id} refresh={handleRefresh}></FormNovoEndereco>
		</div>
	} else {
		return <div onClick={e => setMode(!mode)}>
			<button>
				+ Endereço
			</button>
		</div>
	}
}


function EnderecoInfo(props) {
    return <div>
		<button className="EntregaCard" onClick={e => props.enderecoSelect(props.item.id)}>
			<h2>{props.item.nome}</h2>
		</button>
	</div>
}


function EditClient(id,nome,telefone,email,obs,refresh) {
	var requestOptions = {
		method: 'POST',
	};
	var url = new URL(`https://admin.comfeitico.com/api/cliente/${id}/edit`);
	url.searchParams.append("nome",nome);
	url.searchParams.append("tel",telefone);
	url.searchParams.append("email",email);
	url.searchParams.append("addobs",obs);
	fetch(url, requestOptions)
    .then(res => res.json()).then(
		refresh()
	)
}

export default ClienteInformacoes;