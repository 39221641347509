import React, { useState, useEffect } from 'react';
import FormNovoItem from './novoItem.js'
import Popup from 'reactjs-popup';
import './App.css';
import ItemContainer from './components/ItemContainer.js';

function ItemsMenu() {
    return <div className="App-header">
        <ListaItems/>
        <Popup trigger={<button className="fixedButton"><b> + Item </b></button>} modal>
            <div className="EntregaCard pop-up">
                <FormNovoItem/> 
            </div>
        </Popup>
    </div>
}

function ListaItems() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [modo,setModo] = useState(0);
    const [itemSelecionado,setItemSelecionado] = useState([]);

    function selectItem(e){
        setItemSelecionado(e);
        setModo(1);
    }

    function voltar(){
        setModo(0);
    }

    useEffect(() => {
        setIsLoaded(false);
        fetch(`https://admin.comfeitico.com/api/produtos`)
        .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          setIsLoaded(true);
          setItems(result.produtos);
          setError(false);
        },
        // Nota: é importante lidar com errros aqui
        // em vez de um bloco catch() para não receber
        // exceções de erros reais nos componentes.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
    },[])

    if (error) {
        return <div>
            Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            if (modo === 0) {
            return <div className="wrapper">
                {itemSelecionado.id} {itemSelecionado.nome}
                {console.log(items)}
                {items.map(item => (
                <ItemContainer key={item.id} item={item} select={selectItem}/>
                ))}
            </div>
            } else if (modo === 1) {
                return <div>
                    <button className="fixedBackButton" onClick={e => voltar()}>Voltar</button>
                    <TelaItem item={itemSelecionado}/>
                    {itemSelecionado.id} {itemSelecionado.nome}
                </div>
            } else {
                return <div>
                    <button className="fixedBackButton" onClick={e => voltar()}>Voltar</button>
                    Você não deveria estar vendo isso.
                </div>
            }
        }
}

function TelaItem(props){
    const [nome, setNome] = useState(props.item.nome);
    const [desc,setDesc] = useState(props.item.descricao);
    const [preco,setPreco] = useState(props.item.preco);

    function SaveChanges(){
        var url = new URL(`https://admin.comfeitico.com/api/produto/${props.item.id}/edit`);
        url.searchParams.append("desc",desc);
        url.searchParams.append("nome",nome);
        url.searchParams.append("preco",preco);
        var requestOptions = {
            method: 'POST',
        };
        fetch(url, requestOptions)
				.then(res => res.json())
				.then(
					(result) => {
						console.log(result)
					},
					(error) => {
						console.log(error.toString())
					}
				)
    }

    return <div>
        <input
            name="nomebox"
            className="searchbox"
            value={nome}
            onChange={e => setNome(e.target.value)}>
        </input>
        <input
            name="descbox"
            className="searchbox"
            value={desc}
            onChange={e => setDesc(e.target.value)}
        >
        </input>
        <input
            name="precobox"
            className="searchbox"
            value={preco}
            onChange={e => setPreco(e.target.value)}
        >
        </input>
        <button onClick={SaveChanges()}> Salvar </button>
    </div>
}

// function ItemContainer(props) {
    
//     return <button
//     className="EntregaCard"
//     onClick={e => props.select(props.item)}
//     > 
//         <h2>{props.item.nome}</h2> <br/>
//         <b>Codigo:</b>{props.item.id} <br/>
//         <b>Preço:</b>{props.item.preco} <br/>
//     </button>
// }

export default ItemsMenu;