import styled, { css } from 'styled-components'

function PagamentoSelect({ value , setValue }) {
return <div>
    <Select onChange={e => setValue(e.target.value)} name="pagamentos" id="pag">
        <option value="PIX">Pix</option>
		<option value="Cartão">Cartão</option>
		<option value="Transferencia">Transferencia</option>
		<option value="Outro">Outro</option>
	</Select>
</div>
}

const Select = styled.select`
    border-radius: 36px;
    font-size: 1em;
    font-weight: 300;
`

export default PagamentoSelect;