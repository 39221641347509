import React, { useState, useEffect } from 'react';
import './App.css';

function SeletorCliente(props) {
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [items, setItems] = useState([]);
	const [fresh,setFresh] = useState(props.fresh);
	const [pesquisa,setPesquisa] = useState("");

	const [mode,setMode] = useState(0);

	// Nota: O array [] deps vazio significa
	// este useEffect será executado uma vez
	// semelhante ao componentDidMount()
	useEffect(() => {
		if (fresh === false){
			var url = new URL(`https://admin.comfeitico.com/api/clientepesquisa/${pesquisa}`);
			if (pesquisa === ""){
				url = new URL(`https://admin.comfeitico.com/api/cliente/`);
			}
			fetch(url)
				.then(res => res.json())
				.then(
					(result) => {
						console.log(result)
						console.log(result.clientes)
						var ris = result.clientes
						console.log(ris)
						setIsLoaded(true);
						setItems(ris);
						console.log(items)
						setFresh(true);
					},
					(error) => {
						setIsLoaded(true);
						setError(error);
						console.log(items)
					}
				)
		}
	}, [fresh])


	function PesquisaChange(value) {
		setPesquisa(value);
		setFresh(false);
	}

	if (error) {
	  return <div>Error: {error.message}</div>;
	} else if (!isLoaded) {
	  return <div>Loading...</div>;
	} else {
	  	if (mode === 0){
			return (
				<div>
					<input
					name="searchbox"
					className="searchbox"
					value={pesquisa}
					onChange={e => PesquisaChange(e.target.value)}
					></input>
					<div className="wrapper">
					{items.map(item => (
						<ClienteCard item={item} refresh={setFresh} select={props.select}></ClienteCard>
					))}
					</div>
				</div>
			);
		} else {
			return (
				<div className="wrapper">
				{items.map(item => (
					<ClienteCard item={item} refresh={setFresh} select={props.select}></ClienteCard>
				))}
				</div>
			);
		}
	}
}

function ClienteCard(props) {
	return <div>
		<button className="ClienteCard" onClick={e => props.select(props.item)}>
			<h2>{props.item.nome}</h2> <br/>
			{props.item.telefone} <br/>
  		</button>
  </div>
}

export default SeletorCliente;