import React, { useState, useEffect } from 'react';
import './App.css';

function FormNovoCliente(props) {
    const [telefone, setTelefone] = useState("83 9");
    const [nome, setNome] = useState("Nome");
    
    const [submit,setSubmit] = useState(false)
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    
    useEffect(() => {
        if (submit === false) {
            return;
        }
        var requestOptions = {
            method: 'POST',
        };
        var url = new URL("https://admin.comfeitico.com/api/cliente");
        url.searchParams.append("nome",nome);
        url.searchParams.append("telefone",telefone);
        setIsLoaded(false);
        fetch(url, requestOptions)
        .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          setIsLoaded(true);
          setItems(result.result);
          setError(false);
          props.refresh();
        },
        // Nota: é importante lidar com errros aqui
        // em vez de um bloco catch() para não receber
        // exceções de erros reais nos componentes.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )


  },[submit])

    
    if (submit === false){
    return <div>
        <b>Novo Item</b>
        <form>
            <b>Telefone:</b><br/>
            <input
			name="telefone"
            type="text"
            value={telefone}
            onChange={e => setTelefone(e.target.value)}
			></input><br/>
            <b>Nome:</b><br/>
            <input
			name="nome"
            type="text"
            value={nome}
            onChange={e => setNome(e.target.value)}
			></input><br/>
            <input
            type="submit"
            onClick={e => setSubmit(true)}
            >
            </input>
        </form>
    </div>
    } else {
        return <b> Test</b>
    }

}

export default FormNovoCliente;
