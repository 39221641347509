import styled, { css } from 'styled-components'
import Card from './card';

function ItemContainer({ item , select }) {


return <Card onClick={e => select(item)}>
		<h2>{item.nome}</h2> <br/>
        <b>Codigo:</b>{item.id} <br/>
        <b>Preço:</b>{item.preco} <br/>	
	</Card>

}


export default ItemContainer;