import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import FormNovoCliente from './novoCliente.js';
import ClienteList from './clienteList.js';
import './App.css';
import MainButton from './components/mainbutton.js';


function Clientes() {
    const [fresh,setFresh] = useState(false);
    const [mode,setMode] = useState(false);
    const [pesquisa,setPesquisa] = useState("");

    if (mode) {
        return <div className="App-header">
            <button onClick={e => setMode(false)}>
                Voltar
            </button>
            <FormNovoCliente/>
        </div>
    } else {
        return <div className="App-header">
            <ClienteList fresh={fresh} refresh={setFresh} pesquisa={pesquisa}/>
            <MainButton action={e => setMode(true)} text={"+ Cliente"}/>
        </div>
    }
    
}

export default Clientes;