
import styled, { css } from 'styled-components'


function InformationField(props) {
	if (props.edit === false) {
		if (props.label === "") {
			return <div> 
				<div><h2>{props.value}</h2></div>
			</div>
		} else {
			return <div> 
				<b>{props.label}:</b> {props.value} <br/> 
			</div>
		}
	} else {
		return <div> 
			<b>{props.label} </b>
			<input
			name={props.label}
            type="text"
            value={props.value}
            onChange={e => props.setValue(e.target.value)}
			></input>
			  <br/>
		</div>
	}
	
	
}

export default InformationField;