import { normalizeUnits } from 'moment';
import React, { useState, useEffect } from 'react';
import './App.css';

function FormNovoEndereco({id,cliente,refresh}) {
	const [cidade, setCidade] = useState("João Pessoa");
	const [bairro, setBairro] = useState("Aeroclube");
	const [rua, setRua] = useState("");
	const [nr, setNr] = useState(0);
	const [complemento, setComplemento] = useState("");
	const [ref, setRef] = useState("");
	const [cep, setCEP] = useState(0);
	const [nome, setNome] = useState("Casa");
	

	
  	function handleSubmit() {
		let url = new URL(`https://admin.comfeitico.com/api/cliente/${id}/endereco`);
		let requestOptions = {
			method: 'POST',
		};
		url.searchParams.append("nome",nome);
		url.searchParams.append("cidade",cidade);
		url.searchParams.append("bairro",bairro);
		url.searchParams.append("rua",rua);
		url.searchParams.append("nr",nr);
		url.searchParams.append("comple",complemento);
		url.searchParams.append("ref",ref);
		url.searchParams.append("cep",cep);
		fetch(url,requestOptions)
		.then(res => res.json())
	  		.then(
				(result) => {
				refresh();
				
				},
				// Nota: é importante lidar com errros aqui
				// em vez de um bloco catch() para não receber
				// exceções de erros reais nos componentes.
				(error) => {
				
				}
	  )
  	}

	return <div>
			<form>
			  <InputFieldSubmit value={nome} label="Nome" setValue={setNome}></InputFieldSubmit>
			  <InputFieldSubmit value={cidade} label="Cidade" setValue={setCidade}></InputFieldSubmit>
			  <InputFieldSubmit value={bairro} label="Bairro" setValue={setBairro}></InputFieldSubmit>
			  <InputFieldSubmit value={rua} label="Rua" setValue={setRua}></InputFieldSubmit>
			  <InputFieldSubmit value={nr} label="Nr" setValue={setNr}></InputFieldSubmit>
			  <InputFieldSubmit value={complemento} label="Complemento" setValue={setComplemento}></InputFieldSubmit>
			  <InputFieldSubmit value={ref} label="Referencia" setValue={setRef}></InputFieldSubmit>
			  <InputFieldSubmit value={cep} label="CEP" setValue={setCEP}></InputFieldSubmit>
			  <InputFieldSubmit value={nome} label="Nome" setValue={setNome}></InputFieldSubmit>
			  <input
			  type="button"
			  onClick={e => handleSubmit()}
			  value="Salvar"
			  > 
			  </input>
			</form>
	</div>
	
}

function InputFieldSubmit(props) {
  return <div>
	<b>{props.label}:</b><br/>
					<input
					name={props.label}
					type="text"
					value={props.value}
					onChange={e => props.setValue(e.target.value)}
					></input><br/>
  </div>
}

export default  FormNovoEndereco;
