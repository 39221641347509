import React, { useState, useEffect } from 'react';
import './App.css';
import SeletorCliente from './clienteSeletor';
import SeletorEndereco from './enderecoSeletor';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pt from 'date-fns/locale/pt';
import "react-datepicker/dist/react-datepicker.css";
import EntregaSelect from "./components/entregaSelect"
import PagamentoSelect from "./components/pagamentoSelect"

registerLocale('pt', pt)
function FormNovoPedido(props) {
	const [stage,setStage] = useState(0);
	const [id,setID] = useState(0)
	const [clienteSelecionado,setCliente] = useState([])
	const [EID,setEID] = useState(0);
	const [startDate, setStartDate] = useState(new Date());
	const [EnderecoSelecionado,SelecionarEndereco] = useState([])
	const [obs,setObs] = useState("")
	const [pag,setPag] = useState("Pix")
	const [entrega,setEntrega] = useState(705);

	function SelectCliente(cli) {
		setCliente(cli);
		setStage(1);
	}

	function SelectEndereco(e) {
		setEID(e.id);
		SelecionarEndereco(e)
		setStage(2);
	}
	function CriarPedido() {
		var requestOptions = {
            method: 'POST',
        };
		var url = new URL(`https://admin.comfeitico.com/api/cliente/${clienteSelecionado.id}/pedidos`);
		var dateString = `${startDate.getFullYear()}-${(startDate.getMonth()+1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}-${startDate.getHours().toString().padStart(2, '0')}-${startDate.getMinutes().toString().padStart(2, '0')}`
		url.searchParams.append("dataEntrega",dateString);
		url.searchParams.append("eid",EID);
		url.searchParams.append("obs",obs);
		url.searchParams.append("pag",pag);
		url.searchParams.append("entrega",entrega)
		fetch(url, requestOptions)
				.then(res => res.json())
				.then(
					(result) => {
						console.log(result);
						console.log(result.clientes);
						props.return();
					},
					(error) => {
						console.log(error.toString())
					}
				)
	}

	if (stage === 0) {
		return <div>
			Stage {stage} <br/>
			ID {clienteSelecionado.id}
			<SeletorCliente select={SelectCliente} fresh={false}/>

		</div>
	} else if (stage === 1) {
		return <div>
			{clienteSelecionado.nome} <br/>
			<b>Escolha endereço:</b>
			<SeletorEndereco item={clienteSelecionado} enderecoSelect={SelectEndereco}/>
		</div>
	} else if (stage === 2) {
		return <div>
			<b>Cliente:</b> {clienteSelecionado.nome} <br/>
			<b>Endereço:</b> {EnderecoSelecionado.nome} <br/>
			Data e Hora:
			<div>
				<DatePicker showTimeSelect locale="pt" selected={startDate} onChange={(date) => setStartDate(date)}/> <br/>
			</div>
			Meio de Pagamento:
			<PagamentoSelect value={pag} setValue={setPag} />
			<br/>
			Taxa de Entrega:
			<EntregaSelect value={entrega} setValue={setEntrega} />
			<br/>
			Adcionar Observação:
			<input
			 name="obsbox"
			 className="searchbox"
			 onChange={e => setObs(e.target.value)}>
			</input>
			
			<button onClick={e => CriarPedido()}> Criar Pedido!</button>
		</div>
	} else {
		return <div>
			Stage {stage}
		</div>
	}
}

export default FormNovoPedido;