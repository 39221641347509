import React, { useState, useEffect } from 'react';
import './App.css';
import Popup from 'reactjs-popup';
import FormEditarCliente from './editarCliente';
import MainButton from './components/mainbutton.js';
import TelaEndereco from './components/telaEndereco';
import GetClient from './services/getClient';
import ClienteInformacoes from './components/ClienteInformacoes';

import Card from './components/card';
import Grid from './components/grid';

function ClienteList(props) {
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [items, setItems] = useState([]);
	const [fresh,setFresh] = useState(props.fresh);
	const [pesquisa,setPesquisa] = useState("");

	const [mode,setMode] = useState(0);
	const [clienteSelecionado,setCliente] = useState([]);
	const [enderecoSelecionado,setEnderecoselecionado] = useState(0)


	useEffect(() => {
		if (fresh === false){
			var url = new URL(`https://admin.comfeitico.com/api/clientepesquisa/${pesquisa}`);
			if (pesquisa === ""){
				url = new URL(`https://admin.comfeitico.com/api/cliente/`);
			}
			fetch(url)
				.then(res => res.json())
				.then(
					(result) => {
						console.log(result)
						console.log(result.clientes)
						var ris = result.clientes
						console.log(ris)
						setIsLoaded(true);
						setItems(ris);
						console.log(items)
						setFresh(true);
					},
					(error) => {
						setIsLoaded(true);
						setError(error);
						console.log(items)
					}
				)
		}
	}, [fresh])

	function BackButton() {
		setMode(mode - 1);
		setFresh(false);
	}

	function ClienteSelect(cliente) {
		setMode(1);
		setCliente(cliente);
	}

	function EnderecoSelect(eid){
		setMode(2);
		setEnderecoselecionado(eid);
	}

	function PesquisaChange(value) {
		setPesquisa(value);
		setFresh(false);
	}


	if (error) {
	  return <div>Error: {error.message}</div>;
	} else if (!isLoaded) {
	  return <div>Loading...</div>;
	} else {
	  	if (mode === 0){
			return (
				<div>
					<input
					name="searchbox"
					className="searchbox"
					value={pesquisa}
					onChange={e => PesquisaChange(e.target.value)}
					></input>
					<div className="wrapper">
					{items.map(item => (
						<ClienteCard item={item} refresh={setFresh} select={ClienteSelect}></ClienteCard>
					))}
					</div>
				</div>
			);
		} else if (mode === 1) {
			return  <TelaCliente cliente={clienteSelecionado} backButton={BackButton} refresh={setFresh} enderecoSelect={EnderecoSelect}/>
		} else if (mode === 2) {
			return  <TelaEndereco endereco={clienteSelecionado.enderecos[enderecoSelecionado]} cliente={clienteSelecionado} backButton={BackButton} refresh={setFresh}/>
		} else {
			return (
				<Grid>
				{items.map(item => (
					<ClienteCard item={item} refresh={setFresh} select={ClienteSelect}></ClienteCard>
				))}
				</Grid>
			);
		}
	}
}





function ClienteCard(props) {
	return <Card onClick={e => props.select(props.item)}>
		<h2>{props.item.nome}</h2> <br/>
		{props.item.telefone} <br/>
  </Card>
}

function TelaCliente(props) {

	return <div>
		<button onClick={e => props.backButton()}>
			Sair de {props.cliente.nome}
		</button>
		<ClienteInformacoes cli={props.cliente} refresh={props.refresh} enderecoSelect={props.enderecoSelect}/>
	</div>
}

export default ClienteList;