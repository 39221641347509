import './App.css';
import React, { useState, useEffect } from 'react';
import PedidosList from './pedidosList.js'
import ModeSwitch from "./modeSelector.js"
import ItemsMenu from "./itemsMenu.js"
import Clientes from "./clients.js"
import "typeface-roboto";
import logo from './logo.png';
function App() {
	const [mode,setMode] = useState("Pedidos")
	
	if (mode === "Pedidos"){
		return (
			<div className="App">
				<ModeSwitch mode={mode} switch={setMode}/>
				<PedidosList/>     
			</div>
		);
	} else if (mode === "Items")  {
		return (
			<div className="App">
				<ModeSwitch mode={mode} switch={setMode}/>
				<ItemsMenu></ItemsMenu>
			</div>
		);
	} else if (mode === "Clientes")  {
		return (
			<div className="App">
				<ModeSwitch mode={mode} switch={setMode}/>
				<Clientes></Clientes>
			</div>
		);
	} else {
		return (
			<div className="App">
				<ModeSwitch mode={mode} switch={setMode}/>
				<ItemsMenu></ItemsMenu>
			</div>
		);
	}
}

export default App;
